







import { Component } from 'vue-property-decorator'

import { isRelated } from '../../../../services'
import { log } from '../../../../support'
import { ResolvedSocialPost } from '../../../../models'

import { AbstractModuleUi } from '../../_abstract'

import { HashtagWallModule } from '../HashtagWall.contracts'

/**
 * Container component for the `HashtagWallModuleUi`.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<HashtagWallModuleUi>({
  name: 'HashtagWallModuleUi',
  components: {
    HashtagWallModuleUiPresentation: () => import(
      /* webpackChunkName: "modules/HashtagWall" */
      './HashtagWall.ui.presentation.vue'
    )
  }
})
export class HashtagWallModuleUi extends AbstractModuleUi<HashtagWallModule> {
  /**
   * Posts from `this.module.content` with all related data already resolved and ready to use.
   */
  public posts: ResolvedSocialPost[] = []

  /**
   * @inheritDoc
   */
  public async fetchRelated (): Promise<void> {
    await this.fetchPosts()
  }

  /**
   * Determines whether the component should be rendered.
   */
  public get shouldRender (): boolean {
    return this.hasContent && this.hasPosts
  }

  /**
   * Fetches the social posts from the `RelatedService`.
   */
  private async fetchPosts (): Promise<void> {
    const unresolvedPosts = this.data?.content?.posts

    if (!isRelated(unresolvedPosts)) {
      const message: string = 'HashtagWallModuleUi.fetchPosts(): [this.data.content.posts] is not a valid related object!'
      log([message, unresolvedPosts], 'error')
      return
    }

    try {
      this.posts = await this.relatedService.resolve<ResolvedSocialPost[]>(unresolvedPosts)
    } catch (error) {
      const message: string = 'HashtagWallModuleUi.fetchPosts(): Failed to fetch the related data!'
      log([message, error], 'error')
    }
  }

  /**
   * Determines whether the component has successfully fetched the posts from the `RelatedService`.
   */
  private get hasPosts (): boolean {
    return this.posts.length > 0
  }
}

export default HashtagWallModuleUi
