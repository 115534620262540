





































import { Component, Prop } from 'vue-property-decorator'

import { ResolvedSocialPost } from '../../../../models'

import { AbstractModuleUiPresentation } from '../../_abstract'
import { CardBackgroundColor } from '../../Card'

/**
 * Presentational component for the `HashtagWallModuleUi`.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<HashtagWallModuleUiPresentation>({
  name: 'HashtagWallModuleUiPresentation',
  components: {
    Card: () => import(
      /* webpackChunkName: "molecules/Card" */
      '../../../molecules/Card/Card.vue'
    )
  }
})
export class HashtagWallModuleUiPresentation extends AbstractModuleUiPresentation {
  /**
   * Array of posts to render.
   */
  @Prop({ type: Array, required: true })
  public readonly posts!: ResolvedSocialPost[]

  public CardBackgroundColor = CardBackgroundColor

  /**
   * Determines expanded tile ids array.
   */
  public expandedTileIds: number[] = []

  /**
   * TODO.
   */
  public handleExpansionEvent (postId: number): void {
    if (!this.isTileExpanded(postId)) {
      return this.addExpandedTile(postId)
    }

    this.expandedTileIds = this.expandedTileIds.filter((id) => id !== postId)
  }

  /**
   * Determines is tile expanded.
   */
  public isTileExpanded (tileId: number): boolean {
    return this.expandedTileIds.includes(tileId)
  }

  /**
   * Push tile to expanded tile ids array.
   */
  public addExpandedTile (tileId: number): void {
    this.expandedTileIds.push(tileId)
  }

  /**
   * Determines whether the component has been provided with the correct `posts` prop.
   */
  public get hasPosts (): boolean {
    return typeof this.posts !== 'undefined' &&
      Array.isArray(this.posts) &&
      this.posts.length > 0
  }
}

export default HashtagWallModuleUiPresentation
